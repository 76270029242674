<template>
<div>
  <v-card elevation="0">
    <v-container> 
        <v-row class="mb-5">
          <v-col><h2>Analyze Datasets</h2></v-col>
          <v-col class="text-right"><v-btn @click="show_create_dataset = true" outlined depressed color="primary">Create new Dataset</v-btn></v-col>
        </v-row>
        
        <v-row v-if="!dataset.Trades">
            <v-col>
              <div style="display: flex">
                <div style="flex: 0 1 300px;" class="mr-3">
                  <v-select
                      outlined
                      dense
                      background-color="#FFFFFF"
                      hide-details
                      v-model="selected_dataset"
                      :items="datasets"
                      item-text="name"
                      return-object
                      placeholder="Select One"
                  ></v-select>
              </div>
              <div style="flex: 0 1 200px;">
                <v-btn color="primary" @click="loadDataset" depressed>Load Dataset</v-btn>
              </div>
              </div>
            </v-col>
        </v-row>

      
         <v-card v-if="dataset.Trades" >
            <v-card-title>{{dataset.name}}  <v-spacer></v-spacer> <v-btn class="mr-5" icon  @click="closeDataset"><v-icon>mdi-close</v-icon></v-btn> </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>
                 <div style="display: flex">
                  <div style="flex: 1;">
                    {{dataset.description}}
                  </div>

                   <div style="flex: 0 1;" class="ml-2">  
                    <v-btn  depressed outlined v-show="selected.length" color="error" @click="deleteSelected()">Delete Selected</v-btn>
                  </div>


                  <!-- <div style="flex: 0 1;" class="ml-2">
                      <v-btn  depressed color="primary" @click="analyze()">Run Analysis</v-btn>
                  </div> -->
                  <div style="flex: 0 1 150px;" class="ml-5">
                     <v-switch
                     dense
                      class="mt-0"
                      v-model="show_analysis"
                      label="View Analysis"
                      ></v-switch>
                      
                  </div>
                   <div style="flex: 0 1;" class="ml-2">
                     
                  </div>
                </div>
            
            </v-card-subtitle>
            <v-card-text>
              <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>

            </v-card-text>

            <v-data-table
                show-select
                :page.sync="page"
                :items-per-page="itemsPerPage"
                dense
                hide-default-footer
                checkbox-color="primary"
                :headers="getHeaders"
                :items="dataset.Trades"
                item-key="id"
                selectable-key="id"
                value="id"
                v-model="selected"
                class="elevation-1"
                :search="search"
                :custom-filter="customFilter"
                @current-items="getFiltered"
              >
              
              <template v-slot:item.enter_time="{ item }">
                  {{ item.enter_time | formatDateTime }}
              </template>
            
              <template v-slot:item.one_hour_profit="{ item }">
                    <span  class="table-cell" :style="{ color: item.one_hour_profit > 0 ? '#2ecc70': '#e74d3c ', backgroundColor: item.one_hour_profit > 0 ? '#d3f5e1': '#ffd3cf ' }">{{ item.one_hour_profit }} </span> 
              </template>

                <template v-slot:item.eod_profit="{ item }">
                   <span  class="table-cell" :style="{ color: item.eod_profit > 0 ? '#2ecc70': '#e74d3c ', backgroundColor: item.eod_profit > 0 ? '#d3f5e1': '#ffd3cf ' }">{{ item.eod_profit }} </span> 
              </template>
                <template v-slot:item.next_day_am_profit="{ item }">
                   <span  class="table-cell" :style="{ color: item.next_day_am_profit > 0 ? '#2ecc70': '#e74d3c ', backgroundColor: item.next_day_am_profit > 0 ? '#d3f5e1': '#ffd3cf ' }">{{ item.next_day_am_profit }} </span> 
              </template>
              <template v-slot:item.next_day_eod_profit="{ item }">
                   <span  class="table-cell" :style="{ color: item.next_day_eod_profit > 0 ? '#2ecc70': '#e74d3c ', backgroundColor: item.next_day_eod_profit > 0 ? '#d3f5e1': '#ffd3cf ' }">{{ item.next_day_eod_profit }} </span> 
              </template>

              <!-- <template v-slot:item.two_day_am_profit="{ item }">
                   <span  class="table-cell" :style="{ color: item.two_day_am_profit > 0 ? '#2ecc70': '#e74d3c ', backgroundColor: item.two_day_am_profit > 0 ? '#d3f5e1': '#ffd3cf ' }">{{ item.two_day_am_profit }} </span> 
              </template>
              <template v-slot:item.two_day_eod_profit="{ item }">
                   <span  class="table-cell" :style="{ color: item.two_day_eod_profit > 0 ? '#2ecc70': '#e74d3c ', backgroundColor: item.two_day_eod_profit > 0 ? '#d3f5e1': '#ffd3cf ' }">{{ item.two_day_eod_profit }} </span> 
              </template> -->
               <template v-slot:footer="{ headers }">

                <div class="text-right">
                  
                    
                    <div style="display: inline-block; color: #323232" class="pa-3">
                      Showing  <v-chip small label class="mx-1" color="neutral">{{ ((page - 1) * itemsPerPage) + 1 }} - {{ ((page - 1) * itemsPerPage) + filteredItems.length }}</v-chip>  of <v-chip class="mx-2" small label color="neutral">{{ dataset.Trades.length }}</v-chip> Trades
                    </div>
                    <div style="display: inline-block" class="pa-3">
                      <v-select
                        :value="itemsPerPage"
                        :items="[5,10,20,50,100]"
                        label="Items per page"
                        type="number"
                        outlined
                        dense
                        min="-1"
                        max="15"
                        @input="itemsPerPage = parseInt($event, 10)"
                      ></v-select>
                    </div>
                    <div style="display: inline-block" class="pa-3">
                      <v-pagination
                        total-visible="5"
                        v-model="page"
                        :length="pageCount"
                      ></v-pagination>
                    </div>
       
                </div>                  
                 
                
               </template>
           
            
              <template v-slot:body.append="{ headers }" >
                <tr v-if="show_analysis">
                    <th>Total</th>
                    <th>
                     
                    </th>
                    <th>&nbsp;</th> 
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>{{ filteredItems.reduce((o,n) => o + +n.one_hour_profit , 0).toFixed(2) }}</th>
                    <th>{{ filteredItems.reduce((o,n) => o + +n.eod_profit , 0).toFixed(2) }}</th>
                    <th>{{ filteredItems.reduce((o,n) => o + +n.next_day_am_profit , 0).toFixed(2) }}</th>
                    <th>{{ filteredItems.reduce((o,n) => o + +n.next_day_eod_profit , 0).toFixed(2) }}</th>
               
                </tr>

                <tr>
                  <td :colspan="headers.length" class="pa-3">
                    Data loaded from {{ dataset.load_date | formatSqlDate }} <v-btn class="ml-3" :disabled="isLoadingMore" @click="loadMore" depressed small color="primary">Load Another Month</v-btn>
                  </td>
                </tr>

                
        </template>

              <template v-slot:item.actions="{ item }">
                 <v-btn  color="primary" outlined depressed style="" elevation="0" x-small  @click="viewTrade(item)">
                   <v-icon small>mdi-chart-line</v-icon>
                 </v-btn>
              </template>
              
              </v-data-table>  
          </v-card>


        



      <!-- <v-row v-for="dataset in datasets" :key="dataset.id">
        <v-col><strong>{{dataset.name}}</strong><br />
        {{dataset.description}}
        </v-col> 
        <v-col><v-btn :to="'/traders/?dataset_id=' + dataset.id" color="primary">View</v-btn></v-col>
      </v-row>  -->
    </v-container>
  </v-card>


    <v-dialog persistent v-if="show_create_dataset" v-model="show_create_dataset" max-width="600">
        <template v-slot:default="dialog">
            <v-card>
            <v-toolbar color="primary" dark>
              Create New Dataset
            </v-toolbar>
            <v-card-title>Create New Dataset</v-card-title>
            <v-card-subtitle>Create a New Dataset from a scan
              <v-btn style="float: right;" @click="addFilter()" outlined small >+ New Scan</v-btn>

            </v-card-subtitle>
            <v-card-text class="pa-5"> 
              
                

                <v-container dense>
                  <v-row no-gutters>
                    <v-col cols="3">Name</v-col>
                    <v-col>
                      <v-text-field
                        dense
                        outlined
                        v-model="newdataset.name"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="3">Description</v-col>
                    <v-col>
                      <v-textarea
                        dense
                        outlined
                        rows="3"
                        v-model="newdataset.description"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="3">Scan</v-col>
                    <v-col >
                      
                      <v-select
                        outlined
                        dense
                        background-color="#FFFFFF"
                        
                        v-model="newdataset.scan_id"
                        :items="scans"
                        item-text="name"
                        item-value="id"
                        placeholder="Select One"
                        @change="getScanEstimate"
                    ></v-select>
                    
                    </v-col>
                   
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="3">Min Time</v-col>
                    <v-col>
                      
                      <v-select
                    
                        outlined
                        dense
                        background-color="#FFFFFF"
                        v-model="newdataset.min_time"
                        :items="times"
                        placeholder="Select One"
                        @change="getScanEstimate"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row no-gutters>   
                   <v-col cols="3">Max Time</v-col>
                    <v-col>
                        <v-select
                          outlined
                          dense
                          background-color="#FFFFFF"
                          v-model="newdataset.max_time"
                          :items="times"
                          placeholder="Select One"
                          @change="getScanEstimate"
                        ></v-select>

                    </v-col>
                  </v-row>

                </v-container>

                


              
            </v-card-text>
              <v-divider></v-divider>
            <v-card-actions class="pa-3">
                <span>
                    <v-progress-circular v-show="estimate_loading" size="24" indeterminate color="primary"></v-progress-circular>
                    <span v-show="scan_estimate" >{{scan_estimate}} results per week</span>
                </span>
                <v-spacer></v-spacer>
                <v-btn depressed  @click="closeDatasetWindow">Cancel</v-btn>
                <v-btn depressed color="primary" @click="createDataset">Save New Dataset</v-btn>
            </v-card-actions>
        </v-card>
        </template>
    </v-dialog>

             
         <v-dialog persistent v-if="show_trade" v-model="show_trade" >
            <template v-slot:default="dialog">
                <v-card>

                   <v-card-title>Scan  <v-spacer></v-spacer> 
                      <!-- <v-btn class="mr-5" icon  @click="chartPrevious">
                        <v-icon>mdi-chevron-left</v-icon>
                        </v-btn> 
                        
                      <v-btn class="mr-5" icon  @click="chartNext">
                        <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>  -->
                  </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                
                    <div style="display: flex;" > 
                      <div style="flex: 1" class="pa-4">
                          <dashboard-panel 
                            id="ticker" 
                            :ticker="selected_trade.symbol" 
                            :active_index="active_index" 
                            :current_time="getTime"
                            game_mode="1"
                          ></dashboard-panel>
                      </div>
                      <div style="flex: 1" class="pa-4"> 
                          <dashboard-panel 
                            game_mode="1" 
                            id="spy" 
                            ticker="SPY" 
                            :current_time="getTime">
                          </dashboard-panel>
                      </div>
                  </div>

                    
                </v-card-text>
                  <v-divider></v-divider>
                <v-card-actions class="justify-end pa-3">
                
                    <v-btn outlined depressed color="error" @click="removeTrade">Remove Trade</v-btn>
                    <v-btn text @click="show_trade=false">Close</v-btn>
                </v-card-actions>
            </v-card>
            </template>
        </v-dialog>


     <v-dialog persistent transition="slide-y-transition" max-width="600" class="filter-panel" v-model="showAddFilter">
            <configure-filter v-if="showAddFilter" :selected="selectedFilter" :timeframes="timeframes" :indicators="indicators" @close="showAddFilter = false" @saved="saveFilter" @delete="deleteFilter"></configure-filter>
        </v-dialog>
      

</div>
</template>



<script>  
    const { DateTime } = require("luxon");
    import { EventBus } from '../../modules/EventBus.js';
    import { mapGetters, mapMutations } from 'vuex';
    import ConfigureFilter from '../scanner/ConfigureFilter.vue';

    import DashboardPanel from '../dashboard/DashboardPanel.vue';   
export default {
    name: 'Analyze',
    data: () => ({
      page: 1,
      itemsPerPage: 20,
      isLoadingMore: false,
      selectedFilter: {},
      showAddFilter: false,
      show_create_dataset: false,
      filteredItems: [],
      active_index: 0,
      show_analysis: false,
      show_trade: false,
      selected_trade: {}, 
      ticker: {},
      search: '',
      datasets: [],
      selected_dataset: {}, 
      dataset: {},
      times: [
        '09:30:00',
        '10:00:00',
        '10:30:00',
        '11:00:00',
        '11:30:00',
        '12:00:00',
        '12:30:00',
        '13:00:00',
        '13:30:00',
        '14:00:00',
        '14:30:00',
        '15:00:00',
        '15:30:00',
        '16:00:00',
      ],
      selected: [],
      timeframes: [
        {
            label: 'Minute',
            name: "M5"
        },
        {
            label: 'Day',
            name: "D1"
        } 
      ],
      newdataset: {
        name: '',
        description: '',
        scan_id: '',
        min_time: '',
        max_time: '',
      },
      scan_estimate: '',
      estimate_loading: false
      
    }),
    components:{
      DashboardPanel,
      ConfigureFilter
    },
    filters:{
      formatDateTime(timestamp){
        
        let date = DateTime.fromMillis(timestamp);
        return date.toLocaleString(DateTime.DATETIME_SHORT)
      }, 
      formatTime(timestamp){
        
        let date = DateTime.fromMillis(timestamp );
        
        return date.toLocaleString(DateTime.TIME_SIMPLE)
      },
      formatDate(timestamp){
        
        let date = DateTime.fromMillis(timestamp);
        
        return date.toLocaleString(DateTime.DATE_SHORT)
      },
      formatSqlDate(d){
          let date = DateTime.fromSQL(d);
          
          return date.toLocaleString(DateTime.DATE_SHORT)
      }
    }, 
    created(){
        EventBus.$on('dataset_loaded', this.updateDataset)
        this.getDatasets();

    },
    destroyed(){
      EventBus.$off('dataset_loaded', this.updateDataset)
    }, 
    computed:{
      pageCount(){
        return Math.ceil(this.dataset.Trades.length / this.itemsPerPage)
      }, 
      getHeaders(){
        
        if(!this.show_analysis){
            return [
                { text: 'Symbol', value: 'symbol', },
                { text: 'Date', value: 'enter_time' },
                // { text: 'Time', value: 'enter_time' },
                { text: 'Price', value: 'enter_price' },
                { text: 'Side', value: 'side' },
                { text: 'Actions', value: 'actions' }
            ]
        } else {
            return [
                { text: 'Symbol', value: 'symbol', },
                { text: 'Date', value: 'enter_time' },
                // { text: 'Time', value: 'enter_time' },
                { text: 'Price', value: 'enter_price' },
                { text: 'Side', value: 'side' },
                { text: '1 Hr', value: 'one_hour_profit' },
                { text: 'EOD', value: 'eod_profit' },
                { text: 'Next Day AM', value: 'next_day_am_profit' },
                { text: 'Next Day EOD', value: 'next_day_eod_profit' },
                // { text: 'Two Day AM', value: 'two_day_eod_profit' },
                // { text: 'Two Day EOD', value: 'two_day_eod_profit' },
                { text: 'Actions', value: 'actions' }
            ]


        }
      }, 
      ...mapGetters({
          scans: 'scannerStore/getScans' ,
          preconfiguredScans: 'scannerStore/getPreconfiguredScans' ,
          indicators: 'scannerStore/getIndicators' 
      }),
      getTime(){
            let interval = 1000 * 60 * 5;
            let current_time = Math.round(this.selected_trade.enter_time / interval) * interval ; 
            return DateTime.fromMillis(current_time );
        }
  

    },
    methods: {
      async getScanEstimate(){
        if(this.newdataset.scan_id){
          this.scan_estimate = '';
          this.estimate_loading = true;
          let response = await this.api.get(`scanner/${this.newdataset.scan_id}/historical`, {
            min_time: this.newdataset.min_time,
            max_time: this.newdataset.max_time
          });
          this.estimate_loading = false;
          this.scan_estimate = response.count;
        }
      }, 
      addFilter(){
          this.showAddFilter = !this.showAddFilter;
          this.selectedFilter = {};
      }, 
      async saveFilter(scan){
          this.selectedFilter = {};
          this.showAddFilter = false; 
      }, 
      deleteFilter(filter_id){
          this.selectedFilter = {};
          this.showAddFilter = false; 
          this.getScanResults();
      }, 
      getFiltered(items){
        this.filteredItems = items
      }, 
      async removeTrade(){
        let response = await this.api.post(`datasets/${this.selected_dataset.id}/remove-trades`, {
            trades: [this.selected_trade.id]
          });
          this.selected = [];
          this.selected_trade = {},
          this.show_trade = false;
          await this.loadDataset();
      }, 
      chartPrevious(){
        this.active_index--;
      },
      chartNext(){
        this.active_index++;
      },
      async viewTrade(trade){
        this.selected_trade = trade; 


        this.isLoaded = false;
        let date = {};
        date = DateTime.fromMillis(this.selected_trade.enter_time );

        let market_open = date.set({ hour: 9, minute: 30});
        const diffMins = (date.ts - market_open.ts) / 1000 / 60
        const periods = Math.floor(diffMins / 5);
        this.active_index = periods;
        await this.$store.dispatch('dashboardStore/setDate', {
            date: date.toFormat('yyyy-MM-dd')
        }); 

        let p1 = this.getData('SPY');
        let p2 = this.getData(this.selected_trade.symbol);
        let r = await Promise.all([p1,p2]);

        this.show_trade = true;
      }, 
      async getData(ticker){
            this.isLoaded = false;
            await this.$store.dispatch('dashboardStore/setTicker',  {
                ticker: ticker
            });
        },

        customFilter(t, v, n) {  
           
          if(t === n.enter_time){
            let datetime = DateTime.fromMillis(t,  { zone: 'America/New_York' } );
              let time = datetime.toLocaleString(DateTime.TIME_SIMPLE)
              let date = datetime.toLocaleString(DateTime.DATE_SHORT)
              return time.indexOf(v) >= 0 || date.indexOf(v) >= 0 
          } else {
            v = v.toLowerCase();
            if(!t) return false;
            return t.toLowerCase().indexOf(v) >= 0
          }

        
          // return this.dataset.Trades.filter((t) => {
            
          //   let datetime = DateTime.fromMillis(t.enter_time,  { zone: 'America/New_York' } );
         
          //   console.log("date", time)
          //   console.log(this.search)
          //   return time.indexOf(this.search >= 0) || date.indexOf(this.search >= 0)


          // }) 
        },
        async getDatasets(){
            let response = await this.api.get('datasets');
            this.datasets = response.datasets
        },
        async updateDataset(data){
          if(+data.dataset_id === +this.selected_dataset.id){
            this.loadDataset();
          }
          if(data.complete){
            this.isLoadingMore = false;
          }
        }, 
        async loadDataset(){
          let response = await this.api.get(`datasets/${this.selected_dataset.id}`);
          this.dataset = response.dataset;
          
        },
        closeDataset(){
          this.selected_dataset = {}
          this.dataset = {};
        }, 
        async deleteSelected(){
          let response = await this.api.post(`datasets/${this.selected_dataset.id}/remove-trades`, {
            trades: this.selected.map(s => s.id)
          });
          this.selected = []; 
          await this.loadDataset();
        },
        async analyze(){
          let response = await this.api.post(`datasets/${this.selected_dataset.id}/analyze`);

        },
        async createDataset(){
          let response = await this.api.post(`datasets`, this.newdataset);
          this.closeDatasetWindow();
          await this.getDatasets();

        },
        async loadMore(){
          this.isLoadingMore = true;
          let response = await this.api.post(`datasets/${this.dataset.id}/load-trades`, this.newdataset);
        }, 
        closeDatasetWindow(){
          this.newdataset = {};
          this.show_create_dataset = false;
        }

    }
}
</script>

<style>

.btn {
    text-transform: unset !important;
}

.table-cell{
    display: block;
    padding: 5px;
  
}

.v-pagination__item{
  margin: 0;
  border-radius: 0;
}

</style>
